import { useContext } from 'react';

import { FooterContext } from '../../Footer.context';
import { FooterContactStyled } from './FooterContact.styled';
import { FooterContactProps } from './FooterContact.types';
import FooterLogo from './components/FooterLogo/FooterLogo';
import { FooterAbstract, FooterAddress, FooterEmail, FooterPhone } from './components';

function FooterContact({ children, logoHref }: FooterContactProps) {
  const { testId } = useContext(FooterContext);

  return (
    <FooterContactStyled data-testid={`${testId}ContactComponent`}>
      {logoHref && <FooterLogo href={logoHref} />}
      {children}
    </FooterContactStyled>
  );
}

FooterContact.displayName = 'footerContact';

FooterContact.Abstract = FooterAbstract;
FooterContact.Address = FooterAddress;
FooterContact.Email = FooterEmail;
FooterContact.Phone = FooterPhone;

export default FooterContact;
